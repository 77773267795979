<!-- 承运商注册 -->
<template>
  <div :class="themeClass" class="width-fill bg-F8F9FE">
    <div class="min-width1100 max-width1100 margin-lr-auto box-sizing">
      <!-- 开店协议 -->
      <div class="flex-row margin-tb-20" v-if="isAgreement">
        <div class="left-box bg-fff margin-r-10">
          <div class="text-center left-title bg-F2F2F2"
            style="text-align: left;padding-left: 13px;padding-right: 13px;">商城协议</div>
          <div class="left-content padding-lr-13" :class="item.checked?'bg-DDEBFF':''"
            v-for="(item,index) of agreementData" :key="index" @click="agreementTabClick(item)">{{item.fTitle}}</div>
        </div>
        <div class="right-box padding-tb-25 padding-lr-20 bg-fff">
          <!-- <div class="font-size20 font-weight700 margin-b-20">{{fTitle}}</div> -->
          <div class="richtext-box margin-b-10">
            <RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
          </div>
          <div class="btn-box text-center" :class="countDown>0?'bg-666':'background-color-theme pointer'"
            @click="countDown>0?'':nextStepClick()">{{countDown>0?'阅读满'+countDown+'秒后可同意并继续':'同意并继续'}}</div>
        </div>
      </div>
      <!-- 开店表单 -->
      <div v-else>
        <div class="underReviewBg-img flex-row-center-center" v-if="isThereAStore">
          <div class="underReview-box bg-fff radius10 flex-row-start-center padding-20">
            <div class="flex-row-start-center">
              <!-- v-if="shopData.fApproveStatus == 0 && shopData.fStatus == 0" -->
              <div class="underReview1-img margin-r-35"></div>
              <!-- <div class="underReview254-img margin-r-35" v-if="(shopData.fApproveStatus == 254 && shopData.fStatus == 0) || (shopData.fApproveStatus == 254 && shopData.fStatus == 1)"></div>
							<div class="underReview255-img margin-r-35" v-if="shopData.fApproveStatus == 255 && shopData.fStatus == 0"></div> -->
              <div class="flex-colum">
                <div class="flex-colum color-theme font-size20 font-weight700"
                  v-if="shopData.fApproveStatus == 1 && shopData.fStatus == 0">
                  <div class="margin-b-15">正在审核中！</div>
                  <div class="margin-b-15">我们会尽快为你处理</div>
                </div>
                <div class="flex-colum font-color-3FD09E font-size20 font-weight700"
                  v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 0">
                  <div class="margin-b-15">审核通过！</div>
                  <div class="margin-b-15">我们会尽快为你处理</div>
                </div>
                <div class="flex-colum font-color-FF0000 font-size20 font-weight700"
                  v-if="shopData.fApproveStatus == 255 && shopData.fStatus == 0">
                  <div class="margin-b-15">未通过审核！</div>
                </div>
                <div class="flex-colum font-color-3FD09E font-size20 font-weight700"
                  v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 1">
                  <div class="margin-b-15">您已成为承运商！</div>
                </div>
                <div class="font-color-666 margin-b-10">
                  申请单号：{{shopData.fBillID}}
                </div>
                <!-- <div class="font-color-666 margin-b-25">
									公司名称：{{shopData.fCustomerName}}
								</div> -->
                <div class="underReview-btn text-center border-color-theme color-theme pointer"
                  v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 1" @click="toLogin">
                  开始承运管理
                </div>
                <!-- <div class="flex-row">
									<div class="underReview-btn text-center border-color-theme color-theme pointer" v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 0"
									 @click="downShopSettleInAgreement" v-loading.fullscreen.lock="londing">
										下载店铺入驻协议
									</div>
									<div class="underReview-btn text-center border-color-theme color-theme pointer margin-l-13" v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 0&&AccountPayWalletInfo"
									 @click="toOpenAccountPay" v-loading.fullscreen.lock="londing">
										去开通账服通钱包
									</div>
								</div> -->
                <div class="underReview-btn text-center border-color-theme color-theme pointer"
                  v-if="shopData.fApproveStatus == 255 && shopData.fStatus == 0" @click="formShow">
                  重新申请
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="min-width1100 max-width1100 margin-tb-20 margin-lr-auto" v-else>
          <div class="margin-b-15 bg-fff padding-15">
            <div class="font-size16 margin-b-10">
              您好，{{getUserInfo.customerName}}！欢迎成为聚材通合作伙伴，为平台用户提供商品运输服务！
            </div>
            <div class="font-size18 font-weight700 margin-b-10">
              注册信息
            </div>
            <el-row :gutter="20" style="margin-bottom: 14px;">
              <el-col :span="10" style="width: 50%;">
                <div class="flex-row" style="width: 100%;justify-content: left;">
                  <div class="font-color-666 width-100 " style="text-align: left;">企业名称：</div>
                  <div>{{getuserData.fCustomerName}}</div>
                </div>
              </el-col>
              <el-col :span="10" style="width: 49%;">
                <div class="flex-row" style="width: 100%;">
                  <div class="font-color-666 width-115" style="text-align: left;width: 80px;">银行账号：</div>
                  <div>{{getuserData.fBankAccounts}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-bottom: 14px;">
              <el-col :span="10" style="width: 50%;">
                <div class="flex-row" style="width: 100%; justify-content: left;">
                  <div class="font-color-666 width-100 " style="text-align: left;">统一信用代码：</div>
                  <div>{{getuserData.fIdentityCode}}</div>
                </div>
              </el-col>
              <el-col :span="10" style="width: 49%;">
                <div class="flex-row" style="width: 100%;">
                  <div class="font-color-666 width-115" style="text-align: left;width: 80px;">开户行：</div>
                  <div>{{getuserData.fBankNames}}</div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="bg-fff padding-15 margin-b-10">
            <div class="form-box margin-auto">
              <div class="font-size16 margin-b-15 flex-row-center-center">
                <span class="iconfont icon-biaodan color-theme font-size25"></span>
                申请开通承运商服务
              </div>
              <el-form ref="form" :rules="rules" :model="form" :label-width="formLabelWidth" label-position="right">

                <el-form-item label="单据编码" prop="billNumber">
                  <el-input v-model="form.billNumber" placeholder="请填写单据编码" disabled />
                </el-form-item>
                <el-form-item label="履约保证金(万元)" prop="fDepositMoney">
                  <el-input v-model="form.fDepositMoney" oninput="value=value.replace(/[^0-9.]/g,'')"
                    placeholder="请填写保证金" />
                </el-form-item>
                <el-form-item v-if="!changeAccount" label="运费结算收款银行" prop="fBankTypeID">
                  <el-select v-model="form.fBankTypeID" placeholder="请选择开户行" @change="selectChange"
                    style="width: 100%;">
                    <el-option v-for="(item,index) in bankList" :key="index" :label="item.fBankTypeName"
                      :value="item.fBankTypeID"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-else label="运费结算收款银行" prop="fBankTypeID">
                  <el-select v-model="form.fBankTypeID" :disabled="changeAccount" placeholder="请选择收款银行"
                    @change="selectChange">
                    <el-option v-for="(item,index) in bankList" :key="index" :label="item.fBankTypeName"
                      :value="item.fBankTypeID"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="支行信息" prop="fabankmaname" position="">
                  <el-input v-model="form.fabankmaname" placeholder="请选择支行信息" disabled />
                  <el-button type="primary"
                    style="height: 30px;line-height: 6px;position: absolute;right:0px;top: 5px;padding: 12px 8px;"
                    @click="ToBranchSelection">请选择...</el-button>
                  <div style="color: #FF9900;">支行信息选择错误会影响收款，请仔细核对。</div>
                </el-form-item>
                <el-form-item label="运费结算收款账号" prop="fBanknumber">
                  <el-input v-model="form.fBanknumber" placeholder="请填写运费结算收款账号" />
                </el-form-item>
                <el-form-item label="法人" prop="fLegalPerson">
                  <el-input v-model="form.fLegalPerson" placeholder="请填写法人姓名" />
                </el-form-item>
                <el-form-item label="联系人" prop="fLiaisonMan">
                  <el-input v-model="form.fLiaisonMan" placeholder="请填写联系人姓名" />
                </el-form-item>
                <el-form-item label="联系电话" prop="FPhone">
                  <el-input v-model="form.FPhone" maxlength="11" placeholder="请填写联系电话" />
                </el-form-item>
                <el-form-item label="备注">
                  <el-input v-model="form.fRemark" placeholder="请填写备注" />
                </el-form-item>
                <div v-if="this.outuploadRule.length>0">
                  <div v-for="(item,index) of outuploadRule" :key="index" style="position: relative;">
                    <el-form-item :label="item.rule.fRuleName" :class="item.rule.fIsNecessary==0 ? '': 'isNecessary'">
                      <div class="padding-15 border-F2F2F2">
                        <UploadImgList :accept="item.rule.fFileType" :rules="item.rule"
                          :uploadToken="outuploadToken[index]" :limit="1" v-model="outannexEntityArray[index]"
                          uploadType="image" />
                      </div>
                      <div class="font-color-FF9900 font-size12">*提示：仅支持上传{{item.rule.fFileType}}格式文件；</div>
                    </el-form-item>
                  </div>
                </div>

                <el-form-item label="附件" v-if="this.uploadRule.length>0">
                  <div class="border-color-theme color-theme btn-box1 text-center pointer"
                    @click="dialogVisible = true">
                    + 添加附件
                  </div>
                </el-form-item>
                <div class="flex-row-center-center margin-b-10">
                  <div class="font-color-999 flex-row">
                    <el-checkbox v-model="tradingRules"></el-checkbox>
                    <div class="margin-l-10">我已阅读并同意</div>
                    <div style="width: 300px;" class="flex-row-wrap">
                      <div v-for="(item,index) in AgreementHistList" :key='index' class="color-theme pointer"
                        @click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</div>
                    </div>
                  </div>
                </div>
                <div class="flex-row-center-center">
                  <div class="color-theme border-color-theme submitBtn-box margin-r-10 text-center pointer"
                    v-loading.fullscreen.lock="submitAuditLoading" @click="submitAuditForm">提交审核</div>
                  <div class="background-color-theme submitBtn-box text-center pointer"
                    v-loading.fullscreen.lock="submitLoading" @click="submitForm">暂存</div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="附件区" v-if="dialogVisible" :visible.sync="dialogVisible" width="60%" v-dialogDrag
      :close-on-press-escape="false" :close-on-click-modal="false">
      <div ref="dialogBox" class="dialogScroll">
        <el-form ref="formData" label-width="110px" label-position="left">
          <el-row :gutter="20">
            <el-col :span="12" v-for="(item,index) of uploadRule" :key="index" style="position: relative;">
              <el-form-item :label="item.rule.fRuleName" :class="item.rule.fIsNecessary==0 ? '': 'isNecessaryitem'">
                <div class="padding-15 border-F2F2F2">
                  <UploadImgList :accept="item.rule.fFileType" :rules="item.rule" :uploadToken="uploadToken[index]"
                    :limit="1" v-model="annexEntityArray[index]" uploadType="image" />
                </div>
                <div class="font-color-FF9900 font-size12">*提示：仅支持上传{{item.rule.fFileType}}格式文件；</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="enclosure">确 定</el-button>
      </span>

    </el-dialog>
    <BranchSelection v-if="BranchSelection" :showModel='BranchSelection' :id="fBankCode" @change="branchChange">
    </BranchSelection>
    <agreementDialog :iContents='iContent' ref="areDialog" :list="AgreementHistList" @handleClick="handleClick">
    </agreementDialog>
  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex';
  import UploadImgList from "@/components/upload/UploadImgList.vue";
  import RichTextStyle from "@/components/richtext/RichTextStyle";
  import BranchSelection from './BranchSelection';
  import agreementDialog from '@/components/agreementDialog';
  export default {
    components: {
      UploadImgList,
      RichTextStyle,
      BranchSelection,
      agreementDialog
    },
    data() {
      const validateDepositMoney = (rule, value, callback) => {
        if (!value) {
          callback(new Error('保证金不能为空'))
        } else if (value.toString().indexOf(".") != -1 && value.toString().split('.').length > 2) {
          callback(new Error('请输入正确格式的保证金')) //防止输入多个小数点
        }
        //  else if (value.indexOf(".") != -1 && value.split('.')[1].length > 2) {
        // 	callback(new Error('请输入正确的小数位数')) //小数点后两位
        // }
        else {
          callback();
        }
      };
      const validateFeeRadio = (rule, value, callback) => {
        //console.log("value.toString().split('.').length > 2", value.toString().split('.').length > 2);
        if (!value) {
          callback(new Error('手续费比率不能为空'))
        } else if (value.toString().indexOf(".") != -1 && value.toString().split('.').length > 2) {
          callback(new Error('请输入正确格式的手续费比率')) //防止输入多个小数点
        }
        //  else if (value.indexOf(".") != -1 && value.split('.')[1].length > 2) {
        // 	callback(new Error('请输入正确的小数位数')) //小数点后两位
        // }
        else {
          callback();
        }
      };
      return {
        iContent: '', //协议内容
        AgreementHistList: [], //协议列表
        tradingRules: false, //是否阅读并同意协议
        bankList: [], //银行列表
        changeAccount: false,
        countDown: 10,
        auditLonding: false,
        auditFailedLonding: false,
        submitAuditLoading: false,
        submitLoading: false,
        londing: false,
        isAgreement: false, //是否显示协议
        agreementData: [], //店铺协议
        fTitle: '', //富文本标题
        content: '', //富文本内容
        rule: [], //富文本规则
        fBankCode: '',
        BranchSelection: false,

        isThereAStore: false, //当前用户是否开店
        shopData: {}, //已经开店的店铺信息

        submitShow: false, //当前用户是否开店，开店之后是否初始
        outuploadRule: [],
        outuploadToken: [], //上传图片token
        outlimitTypeArr: '', //上传图片格式规则
        outannexEntityArray: [],
        outfIsNecessaryData: [],



        uploadRule: [], //上传图片规则
        uploadToken: [], //上传图片token
        limitTypeArr: '', //上传图片格式规则
        form: {
          fBillID: '',
          fShopTypeID: '', //店铺类型id
          fAgreementHistID: '', //协议id
          fBankNo: '', //开户行号
          fBankOfDeposit: '', //开户行
          fCollectionAccountNo: '', //收款账号
          fDepositMoney: '', //保证金
          fOfficePhone: '', //办公电话
          fcsPhone: '', //客户电话
          fqsPhone: '', //服务质量电话
          fComplaintsPhone: '', //投诉电话
          fRemark: '', //备注
          fLegalPerson: "", //法人
          fLiaisonMan: "", //联系人
          fBankTypeID: "", //银行ID
          fabankmaname: "", //支行名称
          fabankmaID: "", //支行ID
          fBanknumber: "", //运费结算收款账号
          billNumber: "",
          FPhone: ""
        },
        rules: {
          fShopTypeID: [{
            required: true,
            message: '请选择店铺类型',
            trigger: 'change'
          }],
          fBankNo: [{
            required: true,
            message: '开户行号不能为空',
            trigger: 'blur'
          }],
          fBankOfDeposit: [{
            required: true,
            message: '开户行不能为空',
            trigger: 'blur'
          }],
          fCollectionAccountNo: [{
            required: true,
            message: '收款账号不能为空',
            trigger: 'blur'
          }],
          fBankTypeID: [{
            required: true,
            message: '请选择运费结算收款银行',
            trigger: 'blur'
          }],
          fBanknumber: [{
            required: true,
            message: '请填写运费结算收款账号',
            trigger: 'blur'
          }],
          fabankmaname: [{
            required: true,
            message: '请选择支行信息',
            trigger: 'blur'
          }],

          fDepositMoney: [{
            required: true,
            trigger: 'blur',
            validator: validateDepositMoney
          }],
          fLiaisonMan: [{
            required: true,
            message: '联系人姓名不能为空',
            trigger: 'blur'
          }],
          FPhone: [{
            required: true,
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的联系电话',
            trigger: 'blur'
          }],
          fLegalPerson: [{
            required: true,
            message: '法人姓名不能为空',
            trigger: 'blur'
          }],
          fcsPhone: [{
            required: true,
            message: '客户电话不能为空',
            trigger: 'blur'
          }, {
            required: true,
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }],
          fqsPhone: [{
            required: true,
            message: '服务质量不能为空',
            trigger: 'blur'
          }],
          fComplaintsPhone: [{
            required: true,
            message: '投诉电话不能为空',
            trigger: 'blur'
          }],
          annexEntityArray: [{
            required: true,
            message: '当前图片不能为空',
            trigger: 'change'
          }],
        },
        formLabelWidth: '160px', //label宽

        shopTypeList: [], //店铺类型弹窗数据

        annid: "",
        outannid: "",


        annexEntityArray: [],
        fIsNecessaryData: [],
        pictureIsNecessaryDataName: [],
        getuserData: {
          // fBankNames:""
        },
        dialogVisible: false, //新增编辑弹窗
        promiseTimer: '',
        AccountPayWalletInfo: false,
        annexfBillID: "",
      }
    },
    computed: {
      ...mapGetters(['getThemeName', 'getUserInfo']),
      themeClass() {
        return `theme-${this.getThemeName}`;
      }
    },
    mounted() {
      this.getruleandchengyun();
      // this.getAgreementHistory();
      this.getBillNumber()
      this.getBankList();
      this.getcurrentUser();
      this.getAccountPayWalletInfo();
      this.getListByCarrierApply();
    },
    methods: {
      //获取协议
      getListByCarrierApply() {
        this.ApiRequestPostNOMess('api/mall/ebbase/agreement-history/get-list-by-carrier-apply')
          .then(res => {
            //console.log("获取协议___完成", res);
            this.AgreementHistList = res.obj.items;
          }, error => {});
      },
      handleClick(a) {
        //console.log('a', a);
        this.iContent = a;
      },
      //阅读协议
      showAgreeDia(e) {
        this.iContent = e;
        this.$refs.areDialog.show();
      },
      // 获取单据编码
      getBillNumber() {
        this.ApiRequestPost('/api/mall/ebcustomer/freight-apply-bill/get-bill-number', {}).then(
          (result) => {
            //console.log(result, "获取单据编码");
            this.form.billNumber = result.obj
          },
          (rej) => {

          }
        );

      },
      //选择支行弹窗
      ToBranchSelection() {
        if (this.form.fBankTypeID == '' || this.form.fBankTypeID == undefined || this.form.fBankTypeID == null) {
          this.$message.info('请先选择收款银行!')
          return
        } else {
          this.BranchSelection = true;
        }
      },
      //选择支行信息
      selectChange(e) {
        let fBankTypeCode = this.bankList.find(p => p.fBankTypeID == this.form.fBankTypeID);
        this.fBankCode = fBankTypeCode.fBankTypeCode;
      },
      //   获取开户行下拉框数据
      getBankList() {
        this.ApiRequestPost('/api/mall/ebbase/bank-type/get-acs-banktype', {}).then(
          (result) => {
            this.bankList = result.obj.items;
            //console.log(this.bankList, "开户行");
          },
          (rej) => {

          }
        );
      },
      //选择支行回传数据
      branchChange(e, data) {
        this.BranchSelection = e;
        if (data.isChange) {
          this.form.fabankmaname = data.data[0].fkhmC1
          this.form.fabankmaID = data.data[0].fqhhO2
        }
      },

      //确认附件
      enclosure() {
        let message = [];
        if (this.fIsNecessaryData.length > 0) {
          this.fIsNecessaryData.forEach(item => {
            if (this.annexEntityArray[item.index].length < 1) {
              let text = item.fRuleName + '不能为空';
              message.push(text)
              return
            }
          })
        }
        if (message.length > 0) {
          this.$message.info(message[0])
        } else {
          this.dialogVisible = false;
        }
      },
      //查询店铺协议接口
      downShopSettleInAgreement() {
        this.londing = true;
        this.ApiRequestPost('api/mall/ebcustomer/baseinfo/down-shop-settle-in-agreement').then(res => {
          window.open(res.obj, '_blank')
          this.londing = false;
        }, error => {

        });
      },
      toOpenAccountPay() {
        this.$router.replace({
          path: '/businessme/AccountPay',
        });
      },
      //   获取账服通钱包余额
      getAccountPayWalletInfo() {
        this.ApiRequestPost(
          "api/mall/ebbalance/customer-acs/get-my-ACS"
        ).then(
          (result) => {
            if (result.obj == null || result.obj.fCustomerID == null) {
              this.AccountPayWalletInfo = true;
              return;
            }
          },
          (rej) => {}
        );
      },
      //跳转正式库管理端登录页面
      toLogin() {
        window.open('https://www.xjjcjc.com:9528/#/login', '_black');
      },
      aaa() {
        this.ApiRequestPost('api/mall/ebbalance/customer-acs/get-my-ACS').then(res => {
          if (res.obj == null) {
            this.$confirm('尚未开通账服通，请前往开通后再来开店', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push({
                path: 'businessme/AccountPay'
              });
            }).catch(() => {

            });
          } else {
            this.$router.push('/OpenShop/OpenShopForm')
          }
        }, error => {

        });
      },

      //获取当前登陆人信息
      getcurrentUser() {
        this.ApiRequestPost('api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full', {})
          .then(
            res => {
              this.getuserData = res.obj;
              //console.log(this.getuserData, "this.getuserData")
            }, error => {}
          )
      },
      async getruleandchengyun() {
        await this.getOpenShopAnnexRule()
        await this.getShopApplication()
      },


      //获取当前用户承运商申请信息
      getShopApplication() {

        this.ApiRequestPost('api/mall/ebcustomer/freight-apply-bill/get-by-curret-customer', {})
          .then(res => {

            //console.log('获取当前承运商注册信息', res);
            if (res.obj) {
              if (res.obj.fApproveStatus != 0) {
                this.shopData = res.obj
                this.isThereAStore = true
              }

              this.annexfBillID = res.obj.fBillID
              //console.log("走的if")
              this.isAgreement = false;
              this.form.billNumber = res.obj.fBillNumber
              this.form.fLegalPerson = res.obj.fLegalPerson
              this.form.fLiaisonMan = res.obj.fLiaisonMan
              this.form.fBankTypeID = res.obj.fBankID.toString()
              this.form.fabankmaID = res.obj.fBranchID
              this.form.fBanknumber = res.obj.fAccountNumber
              this.form.fRemark = res.obj.fRemark
              this.form.fabankmaname = res.obj.fkhmC1
              this.form.fDepositMoney = res.obj.fDepositMoney
              this.form.FPhone = res.obj.fPhone
              //console.log('this.form', this.form);
              this.annexEntityArray = [];
              this.outannexEntityArray = [];
              let Type = [];



              let up0 = this.uploadRule;
              let up1 = this.outuploadRule;


              let updata0 = [];
              let updata1 = [];
              // uploadRule

              for (let item of res.obj.annexList) {
                for (let item1 of up0) {
                  if (item.fRuleID == item1.rule.fRuleID) {
                    updata0.push(item)
                  } else {
                    updata1.push(item)
                  }
                }
              }
              for (let i = 0; i < this.uploadRule.length; i++) {
                if (this.uploadRule[i].rule.fIsNecessary == 1) {
                  this.fIsNecessaryData.push({
                    fRuleID: this.uploadRule[i].rule.fAnnexTypeID,
                    fRuleName: this.uploadRule[i].rule.fRuleName,
                    index: i
                  })
                }
              }
              for (let i = 0; i < this.outuploadRule.length; i++) {
                if (this.outuploadRule[i].rule.fIsNecessary == 1) {
                  this.outfIsNecessaryData.push({
                    fRuleID: this.outuploadRule[i].rule.fAnnexTypeID,
                    fRuleName: this.outuploadRule[i].rule.fRuleName,
                    index: i
                  })
                }
              }





              up0.forEach((item, index) => {
                item.rule.fFileType = item.rule.fFileType.split(',');
                for (let i = 0; i < item.rule.fFileType.length; i++) {
                  if (item.rule.fFileType[i].indexOf('.') == -1) {
                    item.rule.fFileType[i] = '.' + item.rule.fFileType[i]
                  }
                }
                item.rule.fFileType = item.rule.fFileType.toString();
                // Type = item.rule.fFileType.split(',');
                this.uploadToken.push({
                  token: item.token
                });
                if (updata0.some(p => p.fRuleID == item.rule.fRuleID)) {
                  var annexe = updata0.find(p => p.fRuleID == item.rule.fRuleID);
                  let tempObj = {};
                  tempObj.fPath = annexe.fPath;
                  tempObj.ruleId = item.rule.fRuleID;
                  tempObj.fAnnexTypeID = annexe.fAnnexTypeID;
                  tempObj.fFileExName = annexe.fFileExName;
                  tempObj.fSourceFileName = annexe.fSourceFileName;
                  this.annexEntityArrayBase = [];
                  this.annexEntityArrayBase.push(tempObj);
                  this.annexEntityArray[index] = this.annexEntityArrayBase;
                } else {
                  this.annexEntityArray[index] = [];
                }
              });



              up1.forEach((item, index) => {
                item.rule.fFileType = item.rule.fFileType.split(',');
                for (let i = 0; i < item.rule.fFileType.length; i++) {
                  if (item.rule.fFileType[i].indexOf('.') == -1) {
                    item.rule.fFileType[i] = '.' + item.rule.fFileType[i]
                  }
                }
                item.rule.fFileType = item.rule.fFileType.toString();
                // Type = item.rule.fFileType.split(',');
                this.outuploadToken.push({
                  token: item.token
                });
                if (updata1.some(p => p.fRuleID == item.rule.fRuleID)) {
                  var annexe = updata1.find(p => p.fRuleID == item.rule.fRuleID);
                  let tempObj = {};
                  tempObj.fPath = annexe.fPath;
                  tempObj.ruleId = item.rule.fRuleID;
                  tempObj.fAnnexTypeID = annexe.fAnnexTypeID;
                  tempObj.fFileExName = annexe.fFileExName;
                  tempObj.fSourceFileName = annexe.fSourceFileName;
                  this.outannexEntityArrayBase = [];
                  this.outannexEntityArrayBase.push(tempObj);
                  this.outannexEntityArray[index] = this.outannexEntityArrayBase;
                } else {
                  this.outannexEntityArray[index] = [];
                }
              });
            } else {
              //console.log("走的else")
              this.getOpenShopAnnexRule();
            }
          })
      },
      showtime() {
        let second = 10;
        if (this.countDown > 0) {
          this.countDown -= 1;
        } else if (this.countDown == 0) {
          clearInterval(this.promiseTimer)
        }
        return this.countDown; //返回倒计时的字符串
      },
      //获取协议内容
      getAgreementHistory() {
        this.ApiRequestPost('api/mall/ebbase/agreement-history/get-distributor-open-shop', {})
          .then(
            res => {
              console.log('获取协议id', res);
              res.obj.items.forEach((item, index) => {
                if (index == 0) {
                  res.obj.items[index].checked = true;
                  this.fTitle = res.obj.items[index].fTitle;
                  this.content = res.obj.items[index].fContent;
                  this.form.fAgreementHistID = res.obj.items[index].fAgreementHistID;
                } else {
                  res.obj.items[index].checked = false;
                }
              });
              this.agreementData = res.obj.items;
            },
            error => {}
          );
      },
      //切换协议
      agreementTabClick(obj) {
        this.agreementData.forEach((item, index) => {
          if (obj.fAgreementHistID == item.fAgreementHistID) {
            this.form.fAgreementHistID = obj.fAgreementHistID;
            this.agreementData[index].checked == true;
          } else {
            this.agreementData[index].checked == false;
          }
        });
      },
      //协议同意并继续
      nextStepClick() {
        //console.log('4556445645');
        this.isAgreement = false;
      },
      //审核不通过
      formShow() {
        // this.getOpenShopAnnexRule();
        this.isThereAStore = false;
      },
      //获取附件验证规则
      getOpenShopAnnexRule() {
        this.ApiRequestPost('api/mall/ebcustomer/freight-apply-bill/get-type-rule')
          .then(
            res => {
              this.uploadRule = res.obj.extendrule;
              this.outuploadRule = res.obj.defaultrule;
              //console.log('获取附件验证规则', this.uploadRule);
              let Type = [];
              for (let i = 0; i < this.uploadRule.length; i++) {
                if (this.uploadRule[i].rule.fIsNecessary == 1) {
                  this.fIsNecessaryData.push({
                    fRuleID: this.uploadRule[i].rule.fAnnexTypeID,
                    fRuleName: this.uploadRule[i].rule.fRuleName,
                    index: i
                  })
                }
              }
              for (let i = 0; i < this.outuploadRule.length; i++) {
                if (this.outuploadRule[i].rule.fIsNecessary == 1) {
                  this.outfIsNecessaryData.push({
                    fRuleID: this.outuploadRule[i].rule.fAnnexTypeID,
                    fRuleName: this.outuploadRule[i].rule.fRuleName,
                    index: i
                  })
                }
              }

              for (let item of res.obj.extendrule) {
                //console.log(2222222);
                item.rule.fFileType = item.rule.fFileType.split(',');
                for (let i = 0; i < item.rule.fFileType.length; i++) {
                  item.rule.fFileType[i] = '.' + item.rule.fFileType[i]
                }
                item.rule.fFileType = item.rule.fFileType.toString();
                // Type = item.rule.fFileType.split(',');
                this.uploadToken.push({
                  token: item.token
                });
              }

              for (let item of res.obj.defaultrule) {
                //console.log(2222222);
                item.rule.fFileType = item.rule.fFileType.split(',');
                for (let i = 0; i < item.rule.fFileType.length; i++) {
                  item.rule.fFileType[i] = '.' + item.rule.fFileType[i]
                }
                item.rule.fFileType = item.rule.fFileType.toString();
                // Type = item.rule.fFileType.split(',');
                this.outuploadToken.push({
                  token: item.token
                });
                //console.log(this.outuploadToken, "获取最终的规则数据")
              }



              //console.log('Type', Type);

              // this.limitTypeArr = Type.toString();
              res.obj.extendrule.forEach((item, index) => {
                if (res.obj.extendrule.some(p => p.fRuleID == item.rule.fRuleID)) {
                  //console.log(123456);
                  var annexe = res.obj.extendrule.find(p => p.fRuleID == item.rule.fRuleID);
                  let tempObj = {};
                  tempObj.fPath = annexe.fPath;
                  tempObj.ruleId = item.rule.fRuleID;
                  tempObj.fAnnexTypeID = annexe.fAnnexTypeID;
                  tempObj.fFileExName = annexe.fFileExName;
                  tempObj.fSourceFileName = annexe.fSourceFileName;
                  this.annexEntityArrayBase = [];
                  this.annexEntityArrayBase.push(tempObj);
                  this.annexEntityArray[index] = this.annexEntityArrayBase;
                } else {
                  this.annexEntityArray[index] = [];
                }
              })

              // this.limitTypeArr = Type.toString();
              res.obj.defaultrule.forEach((item, index) => {
                if (res.obj.defaultrule.some(p => p.fRuleID == item.rule.fRuleID)) {
                  //console.log(123456);
                  var annexe = res.obj.defaultrule.find(p => p.fRuleID == item.rule.fRuleID);
                  let tempObj = {};
                  tempObj.fPath = annexe.fPath;
                  tempObj.ruleId = item.rule.fRuleID;
                  tempObj.fAnnexTypeID = annexe.fAnnexTypeID;
                  tempObj.fFileExName = annexe.fFileExName;
                  tempObj.fSourceFileName = annexe.fSourceFileName;
                  this.outannexEntityArrayBase = [];
                  this.outannexEntityArrayBase.push(tempObj);
                  this.outannexEntityArray[index] = this.outannexEntityArrayBase;
                } else {
                  this.outannexEntityArray[index] = [];
                }
              })

              // this.getShopApplication();
              //console.log('this.limitTypeArr', this.limitTypeArr);
              //console.log('上传图片规则', this.uploadRule);
              //console.log('上传图片token', this.uploadToken);
              //console.log('上传图片格式规则', this.limitTypeArr);
              //console.log('this.annexEntityArray111', this.annexEntityArray);
            },
            // error => {}
          );
      },
      //获取店铺类型弹窗数据
      getShopTypeList() {
        this.ApiRequestPostNOMess('api/mall/ebbase/shop-type/get-onstatus-list', {})
          .then(
            res => {
              //console.log('获取附件验证规则', res);
              this.shopTypeList = [];
              for (let item of res.obj.items) {
                if (item.fShopTypeID != -1) {
                  this.shopTypeList.push(item);
                }
              }
            },
            error => {}
          );
      },
      //保存
      submitForm() {
        this.createRequestbill();
      },
      //创建申请
      createRequestbill() {
        this.submitLoading = true;
        //console.log('this.annexEntityArray', this.annexEntityArray);
        //console.log('this.outannexEntityArray', this.outannexEntityArray);
        let annexList = [];
        let outannexList = [];
        let isNecessaryArry = [];
        let outisNecessaryArry = [];
        this.annexEntityArray.forEach((item, index) => {
          //console.log('item2345', item);
          item.forEach((childitem, childindex) => {
            childitem.fRuleID = childitem.ruleId;
            annexList.push(childitem);
          });
        })
        this.outannexEntityArray.forEach((item, index) => {
          //console.log('item2345', item);
          item.forEach((childitem, childindex) => {
            childitem.fRuleID = childitem.ruleId;
            outannexList.push(childitem);
          });
        })

        //console.log('annexList', annexList);
        //console.log('outannexList', outannexList);


        let data = this.form;
        let params = {}
        if (this.annexfBillID != "") {
          params = {
            fAppTypeID: 2,
            fBillNumber: this.form.billNumber,
            fLegalPerson: this.form.fLegalPerson,
            fLiaisonMan: this.form.fLiaisonMan,
            fBankID: this.form.fBankTypeID,
            fBranchID: this.form.fabankmaID,
            fAccountNumber: this.form.fBanknumber,
            fRemark: this.form.fRemark,
            fPhone: this.form.FPhone,
            annexList: [],
            fDepositMoney: this.form.fDepositMoney == "" ? 0 : this.form.fDepositMoney,
            fBillID: this.annexfBillID
          }
        } else {
          params = {
            fAppTypeID: 2,
            fBillNumber: this.form.billNumber,
            fLegalPerson: this.form.fLegalPerson,
            fLiaisonMan: this.form.fLiaisonMan,
            fBankID: this.form.fBankTypeID,
            fBranchID: this.form.fabankmaID,
            fAccountNumber: this.form.fBanknumber,
            fPhone: this.form.FPhone,
            fRemark: this.form.fRemark,
            annexList: [],
            fDepositMoney: this.form.fDepositMoney == "" ? 0 : this.form.fDepositMoney
          }
        }


        let arr = annexList.concat(outannexList)
        //console.log("当前数据列表", arr)
        params.annexList = arr;

        this.ApiRequestPost('api/mall/ebcustomer/freight-apply-bill/create',
            params)
          .then(
            res => {
              this.getruleandchengyun()
              this.submitLoading = false;
            },
            error => {
              this.submitLoading = false;
            }
          );
      },

      //提交审核
      submitAuditForm() {
        //console.log('this.annexEntityArray', this.annexEntityArray);
        if (this.tradingRules == false) {
          this.$message({
            message: '请先阅读并同意协议',
            type: 'warning'
          });
          return;
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.submitAudit();
          } else {
            //console.log('error submit!!');
            return false;
          }
        });
      },

      //提交审核接口
      submitAudit() {
        // api/mall/ebcustomer/freight-apply-bill/submit-auditing

        this.submitLoading = true;
        //console.log('this.annexEntityArray', this.annexEntityArray);
        //console.log('this.outannexEntityArray', this.outannexEntityArray);
        let annexList = [];
        let outannexList = [];
        let isNecessaryArry = [];
        let outisNecessaryArry = [];
        this.annexEntityArray.forEach((item, index) => {
          //console.log('item2345', item);
          item.forEach((childitem, childindex) => {
            childitem.fRuleID = childitem.ruleId;
            annexList.push(childitem);
          });
        })
        this.outannexEntityArray.forEach((item, index) => {
          //console.log('item2345', item);
          item.forEach((childitem, childindex) => {
            childitem.fRuleID = childitem.ruleId;
            outannexList.push(childitem);
          });
        })

        //console.log('annexList', annexList);
        //console.log('outannexList', outannexList);


        let data = this.form;
        let params = {}
        if (this.annexfBillID != "") {
          params = {
            fAppTypeID: 2,
            fBillNumber: this.form.billNumber,
            fLegalPerson: this.form.fLegalPerson,
            fLiaisonMan: this.form.fLiaisonMan,
            fBankID: this.form.fBankTypeID,
            fBranchID: this.form.fabankmaID,
            fAccountNumber: this.form.fBanknumber,
            fRemark: this.form.fRemark,
            fPhone: this.form.FPhone,
            annexList: [],
            fDepositMoney: this.form.fDepositMoney,
            fBillID: this.annexfBillID
          }
        } else {
          params = {
            fAppTypeID: 2,
            fBillNumber: this.form.billNumber,
            fLegalPerson: this.form.fLegalPerson,
            fLiaisonMan: this.form.fLiaisonMan,
            fBankID: this.form.fBankTypeID,
            fBranchID: this.form.fabankmaID,
            fAccountNumber: this.form.fBanknumber,
            fPhone: this.form.FPhone,
            fRemark: this.form.fRemark,
            annexList: [],
            fDepositMoney: this.form.fDepositMoney,
          }
        }


        let arr = annexList.concat(outannexList)
        //console.log("当前数据列表", arr)
        params.annexList = arr;
        let arrpar = []
        arrpar.push(params)
        this.ApiRequestPost('api/mall/ebcustomer/freight-apply-bill/submit-auditing',
            arrpar)
          .then(
            res => {
              this.getShopApplication()
              this.submitLoading = false;
            },
            error => {
              this.submitLoading = false;
            }
          );
      },

      //审核
      onlineAudit() {
        this.auditLonding = true;
        this.ApiRequestPut('api/mall/ebshop/create-requestbill/online-aduit', {
            id: this.shopData.fBillID
          })
          .then(
            res => {
              this.auditLonding = false;
              this.getShopApplication();
            },
            error => {}
          );
      },
      //审核不同意
      auditFailed() {
        this.auditFailedLonding = true;
        this.ApiRequestPut('api/mall/ebshop/create-requestbill/audit-failed', {
            id: this.shopData.fBillID
          })
          .then(
            res => {
              this.auditFailedLonding = false;
              this.getShopApplication();
            },
            error => {}
          );
      }
    }
  }
</script>

<style lang="scss" scoped>
  .color-theme {
    @include themify($themes) {
      color: themed('themes_color');
    }
  }

  .background-color-theme {
    @include themify($themes) {
      background-color: themed('themes_color');
      color: themed('text_color_white');
    }
  }

  .border-color-theme {
    @include themify($themes) {
      border: 1px solid themed('themes_color');
    }
  }

  .border-bottom-theme {
    @include themify($themes) {
      border-bottom: 1px solid themed('themes_color');
    }
  }

  .left-box {
    height: 748px;
    width: 170px;
  }

  .left-title {
    height: 36px;
    line-height: 36px;
  }

  .left-content {
    height: 41px;
    line-height: 41px;
  }

  .right-box {
    width: calc(1100px - 180px);
  }

  .richtext-box {
    min-height: 575px;
    max-height: 575px;
    overflow-y: auto;
  }

  .btn-box {
    width: 235px;
    height: 45px;
    line-height: 45px;
  }

  .bg-DDEBFF {
    background-color: #DDEBFF;
  }

  .el-row {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .width-100 {
    width: 100px;
  }

  .width-115 {
    width: 115px;
  }

  .form-box {
    width: 500px;
  }

  .underReviewBg-img {
    width: 100%;
    height: 550px;
    // background-image: url(../../../assets/imgs/HomePage/OpenShopUnderReviewBg.png);
    background-size: 100% 100%;
  }

  .underReview-box {
    width: 860px;
    height: 213px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  }

  .underReview1-img {
    width: 258px;
    height: 214px;
    background-image: url(../../../assets/imgs/HomePage/OpenShop1.png);
    background-size: 100% 100%;
  }

  .underReview254-img {
    width: 258px;
    height: 214px;
    background-image: url(../../../assets/imgs/HomePage/OpenShop254.png);
    background-size: 100% 100%;
  }

  .underReview255-img {
    width: 258px;
    height: 214px;
    background-image: url(../../../assets/imgs/HomePage/OpenShop255.png);
    background-size: 100% 100%;
  }

  .underReview-btn {
    width: 160px;
    height: 40px;
    line-height: 40px;
  }

  .submitBtn-box {
    width: 160px;
    height: 40px;
    line-height: 40px;
  }

  .btn-box1 {
    width: 160px;
    height: 40px;
    line-height: 40px;
  }

  .isNecessary:before {
    content: "*";
    position: absolute;
    color: red;
    top: 12px;
    left: 55px;
  }

  .isNecessaryitem:before {
    content: "*";
    position: absolute;
    color: red;
    top: 12px;
    left: 2px;
  }
</style>
